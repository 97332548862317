import React from "react"

import { Container } from 'reactstrap' 
import LayoutEsp from '../../components/layoutEsp'
import SEO from "../../components/seo"
import Slider from '../../components/slider-complaints' 
import worldIcon from '../../assets/images/world.png'
import truckAbsolute from '../../assets/images/complaints.png' 
 
export default () => (
  <LayoutEsp>
    <SEO title="Quejas" />
    <Slider title="QUEJAS"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>   
			  </div>
			</div>
		</Container>
	</div>
	
	<div className="point-crossed-background2" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="credtiH">{'QUEJAS'}</h4>  
				<img className="truckAbsolute2" src={truckAbsolute} alt="truck"/>
			  </div> 
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"> 
				<div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 whiteBack">
					<p className="grayP">{'Lamentamos mucho que haya tenido un problema con su carga.'}</p> 
					<p className="grayText2">Para darle una solución lo antes posible descargue el documento
											que se muestra a continuación y envíelo a info@gutierreztrucking.com
											con la información requerida, un miembro de nuestro equipo se comunicará con usted lo antes posible.</p> 
					<a href="#" className="locationVisit left">{'Documento'}</a> 
				</div>
			  </div> 
			</div>
		</Container>
	</div>  
  </LayoutEsp>
)
